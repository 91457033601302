import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Button from "./reusable/Button";
import TextInput from "./reusable/TextInput";
import SignUpWithGoogle from "./SignUpWithGoogle";
import Dots from "./Dots";
import "./Onboarding.scss";
import stravaButton from "../images/btn_strava_connectwith_orange.svg";

import info1 from "../images/onboarding/info_1.png";
import info2 from "../images/onboarding/info_2.png";
import info3 from "../images/onboarding/info_3.png";
import info4 from "../images/onboarding/info_4.png";
import { addEmail } from "../utils/db";
import Checkbox from "./reusable/Checkbox";
import { BASE_URL } from "../config";

const Onboarding = () => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [continueWithoutStrava, setContinueWithoutStrava] = useState(false); // New state
  const [emailSubmitted, setEmailSubmitted] = useState(false); // New state for email submission
  const [birthYear, setBirthYear] = useState("");
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const moveOnToNextScreen = () => {
    if (currentScreen < screens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    }
  };

  const screens = [
    {
      title: "The factors to a long life",
      subtitle: (
        <>
          <b>
            Exercise is the most important and controllable factor of longevity.
          </b>{" "}
          <br />
          <br />
          Longevity isn't just about how long you live (lifespan), but also how
          well you live your last decades - healthspan. It's about still being
          able to play with grandkids, garden, or climb stairs at 80.
        </>
      ),
      image: info2,
      showInDots: true, // Added boolean
      buttonText: "Continue",
    },

    {
      title: "The longevity triangle",
      subtitle: (
        <>
          The very same actions that enhance lifespan also improve healthspan.
          <b>
            {" "}
            Cardio, strength, and stability together form the triangle
            foundation of a long and healthy life.
          </b>
          <br />
          <br />
          Cardio boosts heart health, strength prevents bone and muscle loss,
          and stability reduces fall risk.
        </>
      ),

      image: info3,
      showInDots: true, // Added boolean

      buttonText: "Continue",
    },

    {
      title: (
        <>
          How <i> More Life</i> works
        </>
      ),
      subtitle: (
        <>
          We use your training data from Strava to calculate key longevity
          metrics and build your <i>More Life</i> profile.
          <br />
          <br />
          For example, we use VO2 max to assess cardiovascular health. It
          measures how efficiently your body uses oxygen during exercise, and is
          one of the best predictors of longevity.
        </>
      ),

      image: info4,
      showInDots: true, // Added boolean

      buttonText: "Continue",
    },

    {
      title: "Connect with Strava",
      subtitle:
        "To provide you with the most accurate longevity insights, we need to know your birth year.",
      component: (
        <div className="input-container">
          <TextInput
            placeholder="Email (john@doe.com)"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setShowError(false);
            }}
            validation={validateEmail}
            showError={true}
          />

          <TextInput
            placeholder="Birth year (1990)"
            value={birthYear}
            onChange={(e) => {
              setBirthYear(e.target.value);
              setShowError(false);
            }}
            validation={(value) => ({
              validated: parseInt(value) >= 1900 && parseInt(value) <= 2020,
              error_message: "Please enter a valid year between 1900 and 2020",
            })}
            showError={true}
          />

          {showError && (
            <div className="error-message">
              Please fill in both birth year and email before connecting.
            </div>
          )}
        </div>
      ),
      buttons: (
        <div className="button-container">
          <img
            src={stravaButton}
            alt="Connect with Strava"
            height="48px"
            onClick={() => {
              if (birthYear && validateEmail(email).validated) {
                window.location.href = `${BASE_URL}/authorize?birth_year=${birthYear}&email=${encodeURIComponent(
                  email
                )}`;
              } else {
                setShowError(true);
              }
            }}
          />
        </div>
      ),
      showInDots: true,
    },
    {
      title: "Get notified when this option becomes available",
      subtitle: (
        <>
          Right now, only the Strava quick win is available. Please enter your
          email to get notified when other options become available.
        </>
      ),
      component: (
        <CollectEmailScreen
          moveOnToNextScreen={() => setEmailSubmitted(true)}
        />
      ),
    },
    {
      title: "Success ✅",
      subtitle: <></>,
      component: (
        <div className="success-message">
          {" "}
          We have registered your email. You will be notified when this option
          becomes available.
        </div>
      ),
    },
  ];

  const { title, subtitle, image, buttons, buttonText, component } =
    continueWithoutStrava
      ? emailSubmitted
        ? {
            title: <span className="text-align-center">Success ✅</span>,

            // subtitle: <></>,
            component: (
              <div className="success-message">
                {" "}
                We have registered your email. You will be notified when this
                option becomes available.
              </div>
            ),
          }
        : {
            title: "Get notified when this option becomes available",
            subtitle: (
              <>
                Right now, only the Strava quick win is available. Please enter
                your email to get notified when other options become available.
              </>
            ),
            component: (
              <CollectEmailScreen
                moveOnToNextScreen={() => setEmailSubmitted(true)}
              />
            ),
          }
      : screens[currentScreen];

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentScreen < screens.length - 1 && !continueWithoutStrava) {
        setCurrentScreen(currentScreen + 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const visibleScreens = screens.filter((screen) => screen.showInDots);

  return (
    <div className="onboarding">
      <div className="header">
        <h1 className="onboarding-title">{title}</h1>
        {subtitle && <h2 className="onboarding-subtitle">{subtitle}</h2>}
      </div>
      <div className="content">
        {component
          ? component
          : image && <img src={image} alt={title} className="info-image" />}
      </div>
      <div className="footer">
        {!continueWithoutStrava && (
          <Dots total={visibleScreens.length} current={currentScreen} />
        )}
        {buttons
          ? buttons
          : buttonText && (
              <div className="button-container">
                <Button
                  color="black"
                  cta={buttonText}
                  onClick={moveOnToNextScreen}
                />
              </div>
            )}
      </div>
    </div>
  );
};

const CollectEmailScreen = ({ moveOnToNextScreen }) => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const { validated } = validateEmail(value);
    setEmailValid(validated);
  };

  const handleNextClick = async () => {
    if (emailValid && marketingConsent) {
      try {
        await addEmail(email); // Call the addEmail function
        moveOnToNextScreen();
      } catch (error) {
        console.error("Error adding email: ", error);
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="collectEmailScreen">
      <TextInput
        placeholder="Email"
        value={email}
        onChange={handleEmailChange}
        validation={validateEmail}
        showError={showError}
        className="email-field"
      />
      <Checkbox
        label="Get notified when feature becomes available and further updates, max once a month"
        checked={marketingConsent}
        onChange={() => setMarketingConsent(!marketingConsent)}
      />
      <div className="button-container">
        <Button
          color="black"
          cta="Submit"
          onClick={handleNextClick}
          disabled={!emailValid || !marketingConsent}
          className="submit-button"
        />
        {showError && (!emailValid || !marketingConsent) && (
          <div className="error-message">
            {emailValid
              ? "You must agree to the marketing disclaimer"
              : "Invalid email format"}
          </div>
        )}
      </div>
    </div>
  );
};

const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value)
    ? { validated: true, error_message: "" }
    : { validated: false, error_message: "Invalid email format" };
};

export default Onboarding;
