import React from "react";
import "./Landing.scss";
import logo from "../images/logo.png";
import profile from "../images/profile_for_landing.png";
import Button from "./reusable/Button";
import config from "../config.json";

const Landing = () => {
  const isProd =
    window.location.href.includes("morelife") ||
    window.location.href.includes("more-life");

  return (
    <div className="page-container">
      <div className="content-container">
        <img src={logo} alt="Logo" className="logo" />
        <h1>
          Get <span>more life</span> out of your training
        </h1>
        <h2>
          Calculate your life expectancy, and get actionable training advice to
          live longer and healthier.
        </h2>

        {(!isProd || config.showJourneyStartOnProd) && (
          <>
            {" "}
            <div className="button-container">
              <Button
                color="burn"
                cta="Start"
                onClick={() => (window.location.href = "/onboarding")}
              />
            </div>{" "}
            {/* <a href="#" className="no-strava-link">
              I don't have Strava
            </a>{" "} */}
          </>
        )}

        <img src={profile} alt="Profile Preview" className="profile-preview" />
      </div>

      <footer>
        <p>&copy; More Life 2024. All rights reserved.</p>
        <p>
          <a href="/privacy-policy">Privacy Policy</a> {"  "}
          <a href="/support">Support</a>
          {/* | */}
          {/* <a href="mailto:contact@example.com">Contact</a> */}
        </p>
      </footer>
    </div>
  );
};

export default Landing;
