import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Ensure this path is correct based on your project structure
import { BASE_URL } from "../config"; // Import the backend URL

/**
 * All-purpose function to write data to Firestore
 * @param {string} collectionName - The name of the collection
 * @param {Object} data - The data to be written to the collection
 * @returns {Promise} - A promise that resolves with the document reference
 */
const writeToDb = async (collectionName, data) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    console.log("Document written with ID: ", docRef.id);
    return docRef;
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
};

/**
 * Function to add a new email to the 'email' collection
 * @param {string} email - The email to be added
 * @returns {Promise} - A promise that resolves with the document reference
 */
const addEmail = async (email) => {
  console.log("Function starts, writing email", email);
  const data = { email, timestamp: new Date() };

  // Ping the webhook
  try {
    await fetch(`${BASE_URL}/trigger_webhook`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webhook_url: "https://hooks.zapier.com/hooks/catch/5198250/2ty0igw/",
        payload: { email },
      }),
    });
    console.log("Webhook pinged successfully");
  } catch (error) {
    console.error("Error pinging webhook: ", error);
  }

  return writeToDb("email", data);
};

export { writeToDb, addEmail };
