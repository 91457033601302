import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBtB3rVQWJP64igwFi5Ur6v7jJr0lSVb3w",
  authDomain: "more-life-3ad58.firebaseapp.com",
  projectId: "more-life-3ad58",
  storageBucket: "more-life-3ad58.appspot.com",
  messagingSenderId: "856987238713",
  appId: "1:856987238713:web:dff80e5bf8ca8d54c228e8",
  measurementId: "G-0FGTM435BY",
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, analytics, db };
