import React from "react";
// import { auth } from "../utils/firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Button from "./reusable/Button";

const SignUpWithGoogle = ({ moveOnToNextScreen }) => {
  const handleGoogleSignUp = () => {
    return;

    // const provider = new GoogleAuthProvider();
    // signInWithPopup(auth, provider)
    //   .then((result) => {
    //     // Handle successful sign-in
    //     moveOnToNextScreen();
    //   })
    //   .catch((error) => {
    //     // Handle errors
    //     console.error(error);
    //   });
  };

  return (
    <Button
      color="google"
      cta="Sign up with Google"
      onClick={handleGoogleSignUp}
    />
  );
};

export default SignUpWithGoogle;
