export function getPercentileColor(percentile) {
  // Convert percentile from "Top X%" format to a decimal
  percentile =
    parseFloat(percentile.replace("Top ", "").replace("%", "")) / 100;

  // Invert the percentile (because "Top 10%" is actually the 90th percentile)
  percentile = 1 - percentile;

  // Ensure percentile is a valid number between 0 and 1
  percentile = Math.max(0, Math.min(1, Number(percentile) || 0));

  if (percentile >= 0.95) {
    return { background: "rgb(191, 255, 191)", border: "#00ff00" }; // Lighter bright green for top 5%
  } else if (percentile >= 0.9) {
    return { background: "rgb(201, 237, 223)", border: "#27c685" }; // Lighter less bright green for 90-95%
  } else if (percentile >= 0.7) {
    // Transition from lighter yellow to lighter less bright green for 70-90%
    const t = (percentile - 0.7) / 0.2;
    const r = Math.round(255 - t * 216);
    const g = Math.round(255 - t * 57);
    const b = Math.round(191 + t * 32);
    return {
      background: `rgb(${r}, ${g}, ${b})`,
      border: `rgb(${r}, ${g}, ${b - 191})`,
    };
  } else if (percentile >= 0.5) {
    // Transition from lighter orange to lighter yellow for 50-70%
    const t = (percentile - 0.5) / 0.2;
    const r = 255;
    const g = Math.round(210 + t * 45);
    const b = Math.round(191 - t * 191);
    return {
      background: `rgb(${r}, ${g}, ${b})`,
      border: `rgb(${r}, ${g - 45}, 0)`,
    };
  } else {
    // Transition from lighter bright red to lighter orange for 0-50%
    const t = Math.min(percentile / 0.5, 1);
    const r = 255;
    const g = Math.round(191 + t * 19);
    const b = Math.round(191 - t * 191);

    const background = `rgb(${r}, ${g}, ${b})`;
    const border = `rgb(${r}, ${g - 191}, 0)`;
    console.log(`Percentile: ${percentile}, RGB: ${r}, ${g}, ${b}`);
    return {
      background,
      border,
    };
  }
}
