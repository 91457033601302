// src/components/reusable/Button.js
import React from "react";
import "./Button.scss";

const Button = ({ color, icon, cta, onClick, disabled }) => (
  <button className={`button ${color}`} onClick={onClick} disabled={disabled}>
    {icon && <span className="icon">{icon}</span>}
    {cta}
  </button>
);

export default Button;
