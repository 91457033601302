import React from "react";
import "./Dots.scss";

const Dots = ({ total, current }) => {
  return (
    <div className="dots">
      {Array.from({ length: total }).map((_, index) => (
        <div
          key={index}
          className={`dot ${index === current ? "active" : ""}`}
        ></div>
      ))}
    </div>
  );
};

export default Dots;
