import React from "react";

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>
      This Privacy Policy is divided into two sections: "About Our Website" and
      "About Our Product." We value your privacy and are committed to protecting
      your personal information. Our goal is to be as transparent and
      privacy-friendly as possible, ensuring your data is handled with the
      utmost care and respect.
    </p>

    <h2>About Our Website</h2>
    <h3>Introduction</h3>
    <p>
      We collect very minimal information on our website and do not use cookies
      or any tracking technologies. The information we collect is solely to
      provide and improve our services.
    </p>

    <h3>Information We Collect</h3>
    <ul>
      <li>
        <strong>Personal Information</strong>: We may collect personal
        information that you provide directly to us, including but not limited
        to your name and email address.
      </li>
      <li>
        <strong>Automatically Collected Information</strong>: When you use our
        website, we may also collect certain information automatically, such as
        IP address, browser type and version, operating system, referral source,
        pages visited, and time spent on our site.
      </li>
    </ul>

    <h3>How We Use Information</h3>
    <p>We use the information we collect to:</p>
    <ul>
      <li>Provide, operate, and maintain our website and services.</li>
      <li>
        Communicate with you, including sending updates, security alerts, and
        support messages.
      </li>
      <li>Analyze and improve our services and website.</li>
    </ul>

    <h3>Sharing of Information</h3>
    <p>
      We do not share your personal information with third parties except in the
      following circumstances:
    </p>
    <ul>
      <li>
        <strong>With Your Consent</strong>: We may share information with your
        consent or at your direction.
      </li>
      <li>
        <strong>For Legal Reasons</strong>: We may share information if we
        believe it is necessary to comply with a legal obligation or to protect
        our rights, property, or safety of our users or the public.
      </li>
    </ul>

    <h3>Data Security</h3>
    <p>
      We implement appropriate technical and organizational measures to protect
      your personal information from unauthorized access, use, or disclosure.
      However, no method of transmission over the internet or method of
      electronic storage is 100% secure, and we cannot guarantee absolute
      security.
    </p>

    <h3>Data Retention</h3>
    <p>
      We retain your personal information only for as long as necessary to
      fulfill the purposes outlined in this Privacy Policy, comply with legal
      obligations, resolve disputes, and enforce our agreements.
    </p>

    <h2>About Our Product</h2>
    <h3>Product Information</h3>
    <ul>
      <li>
        <strong>Authorized Metrics</strong>: We only import authorized metrics
        you choose from the connected devices.
      </li>
      <li>
        <strong>Privacy Control</strong>: You control what's on your public
        profile, can remove our access to your connected devices, and delete
        your profile at any time.
      </li>
      <li>
        <strong>No Third-Party Sharing</strong>: We never share your information
        with any third party without your explicit consent.
      </li>
      <li>
        <strong>GDPR Compliance</strong>: Our services are hosted in the EU and
        are fully GDPR compliant.
      </li>
      <li>
        <strong>Use of Strava Data</strong>: We collect data from your Strava
        account only with your explicit consent. This data is used solely to
        provide and improve our services to you. We do not modify or edit any
        Strava Data, and it is displayed as received through the Strava API.
      </li>
      <li>
        <strong>Strava Data Retention</strong>: We retain Strava data only as
        long as necessary to fulfill the purpose for which it was collected. If
        you revoke our access to your Strava account, all related data will be
        deleted from our servers.
      </li>
      <li>
        <strong>Security Measures for Strava Data</strong>: We use commercially
        reasonable and appropriate measures, including encryption and secure
        transmission (HTTPS), to protect the data collected via the Strava API.
      </li>
      <li>
        <strong>Strava Branding and Attribution</strong>: We comply with
        Strava’s branding and attribution requirements, including the correct
        use of Strava logos and links where their data is used.
      </li>
    </ul>

    <h3>Children's Privacy</h3>
    <p>
      Our website and services are not intended for children under the age of
      16. We do not knowingly collect personal information from children under
      16. If you are a parent or guardian and you are aware that your child has
      provided us with personal information, please contact us. If we become
      aware that we have collected personal information from children under 16
      without verification of parental consent, we take steps to remove that
      information from our servers.
    </p>

    <h3>Your Rights</h3>
    <p>
      Under the General Data Protection Regulation (GDPR), you have the
      following rights regarding your personal data:
    </p>
    <ul>
      <li>
        <strong>Right to Access</strong>: You have the right to request a copy
        of the personal data we hold about you.
      </li>
      <li>
        <strong>Right to Rectification</strong>: You have the right to request
        the correction of any inaccurate or incomplete personal data.
      </li>
      <li>
        <strong>Right to Erasure</strong>: You have the right to request the
        deletion of your personal data when it is no longer necessary for the
        purposes for which it was collected.
      </li>
      <li>
        <strong>Right to Restrict Processing</strong>: You have the right to
        request the restriction of processing your personal data under certain
        circumstances.
      </li>
      <li>
        <strong>Right to Data Portability</strong>: You have the right to
        request the transfer of your personal data to another organization or
        directly to you, under certain conditions.
      </li>
      <li>
        <strong>Right to Object</strong>: You have the right to object to the
        processing of your personal data under certain circumstances, including
        for direct marketing purposes.
      </li>
    </ul>
    <p>
      To exercise any of these rights, please contact us at{" "}
      <a href="mailto:support@getmorelife.io">support@getmorelife.io</a>. We
      will respond to your request within one month.
    </p>

    <h3>International Transfers</h3>
    <p>
      Your personal information may be transferred to, and maintained on,
      computers located outside of your state, province, country, or other
      governmental jurisdiction where the data protection laws may differ from
      those of your jurisdiction. We will take all steps reasonably necessary to
      ensure that your data is treated securely and in accordance with this
      Privacy Policy.
    </p>

    <h3>Changes to This Privacy Policy</h3>
    <p>
      We may update this Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page. You are
      advised to review this Privacy Policy periodically for any changes.
      Changes to this Privacy Policy are effective when they are posted on this
      page.
    </p>
  </div>
);

export default PrivacyPolicy;
