import React from "react";
import "./Profile.scss";
import { getPercentileColor } from "../utils/front";
import { profileMetrics, distributionTables } from "../utils/profile_data";
import stravaLogo from "../images/pwd_strava.svg";
import shareIcon from "../images/shareIcon.png";

const Profile = ({ userData }) => {
  if (!userData) {
    return null;
  }
  const { user_info, metrics } = userData;
  const { firstname, lastname, profile } = user_info.core;
  const age = userData.age || 30; // Fallback to 30 if age is not provided
  const gender = user_info.core.gender;

  const calculatePercentile = (metricCode, value) => {
    console.log(`Calculating percentile for ${metricCode}, value: ${value}`);
    const distribution = distributionTables[metricCode];
    if (!distribution) {
      console.log(`No distribution found for ${metricCode}`);
      return null;
    }

    console.log(`Subject is ${gender} of ${age} years old`);
    const genderDistribution = distribution[gender];
    if (!genderDistribution) {
      console.log(`No distribution found for gender ${gender}`);
      return null;
    }

    const ageRanges = Object.keys(genderDistribution).map(Number);
    const closestAge = ageRanges.reduce((prev, curr) =>
      Math.abs(curr - age) < Math.abs(prev - age) ? curr : prev
    );

    console.log(
      `Taking the percentages for ${gender} of age class ${closestAge}`
    );
    const ageDistribution = genderDistribution[closestAge];
    console.log(`Age distribution:`, ageDistribution);
    const percentiles = Object.entries(ageDistribution);

    for (let i = 0; i < percentiles.length; i++) {
      const [percentile, threshold] = percentiles[i];
      console.log(
        `Comparing value ${value} with threshold ${threshold} for percentile ${percentile}`
      );
      if (value <= threshold) {
        const topPercent = Math.round(100 - Number(percentile) * 100);
        console.log(`Subject is in the top ${topPercent}%`);
        return `Top ${topPercent}%`;
      }
    }

    console.log(`Subject is in the top 1%`);
    return "Top 1%";
  };

  const getMetricData = (metricInfo) => {
    const { metric_code } = metricInfo;
    console.log(`Getting metric data for ${metric_code}`);
    const userMetric = metrics[metric_code.toLowerCase()];

    if (!userMetric) {
      console.log(`No user metric found for ${metric_code}`);
      return {
        ...metricInfo,
        value: null,
        precision: 0,
        percentile: null,
        decimalPlaces: 0,
      };
    }

    const percentile = calculatePercentile(metric_code, userMetric.value);

    return {
      ...metricInfo,
      value: userMetric.value,
      precision: userMetric.precision,
      percentile,
      decimalPlaces: metricInfo.decimalPlaces || 0,
    };
  };

  const renderMainWidget = (metric) => (
    <div key={metric.metric_code} className="widget main-widget">
      <div className="header">
        <div className="emoji">{metric.emoji}</div>
        <div className="title-container">
          <div className="title">{metric.name}</div>
          {/* <div className="metricDescription">{metric.description}</div> */}
        </div>
      </div>
      <div className="metric">
        <div className="metricValue">
          {typeof metric.value === "number"
            ? metric.value.toFixed(metric.decimalPlaces)
            : metric.value}
          {metric.show_unit && <span className="units"> {metric.unit}</span>}
        </div>

        {/* <div className="metricValue">
        {typeof metric.value === "number"
          ? `${metric.value.toFixed(metric.decimalPlaces)} healthy years`
          : `${metric.value} healthy years`}
      </div> */}

        {metric.percentile && (
          <div
            className="percentile"
            style={{
              backgroundColor: getPercentileColor(metric.percentile).background,
              border: `1px solid ${
                getPercentileColor(metric.percentile).border
              }`,
            }}
          >
            {metric.percentile}
          </div>
        )}
      </div>
    </div>
  );

  const renderDetailedWidget = (metric) => (
    <div
      key={metric.metric_code}
      className={`widget ${metric.value === null ? "missing-metric" : ""}`}
    >
      <div className="header">
        <div className="emoji">{metric.emoji}</div>
        <div className="title-container">
          <div className="title">{metric.name}</div>
          <div className="metricDescription">{metric.description}</div>
        </div>
      </div>
      <div className="metric-container">
        {metric.value !== null ? (
          <>
            <div className="metricSmall">
              {typeof metric.value === "number"
                ? metric.value.toFixed(metric.decimalPlaces)
                : metric.value}
              {metric.show_unit && (
                <span className="units"> {metric.unit}</span>
              )}
            </div>
            {metric.percentile && (
              <div
                className="percentileSmall"
                style={{
                  backgroundColor: getPercentileColor(metric.percentile)
                    .background,
                  border: `1px solid ${
                    getPercentileColor(metric.percentile).border
                  }`,
                }}
              >
                {metric.percentile}
              </div>
            )}
          </>
        ) : (
          <div className="missing-data-message">
            More data needed: {metric.minimalRequirement}
          </div>
        )}
      </div>
    </div>
  );

  const mainMetrics = profileMetrics
    .filter((m) => m.isMainWidget)
    .map(getMetricData)
    .filter(Boolean);
  const detailedMetrics = profileMetrics
    .filter((m) => !m.isMainWidget)
    .map(getMetricData)
    .filter(Boolean);

  const handleShare = () => {
    const shareData = {
      title: "Check out my profile on More Life",
      text: "See my longevity metrics and more!",
      url: "https://getmorelife.io/profile",
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing", error));
    } else {
      window.open(
        `mailto:?subject=${shareData.title}&body=${shareData.text} ${shareData.url}`
      );
    }
  };

  const demoMode = window.location.href.includes("profile");

  return (
    <div className="container">
      <div className="profileHeader">
        <div className="profileInfo">
          <div className="profile-image-and-name">
            {demoMode ? (
              <img
                src={require("../images/usain.png")}
                alt="Profile"
                className="profile-image"
              />
            ) : (
              <img src={profile} alt="Profile" className="profile-image" />
            )}
            <h1 className="profileName">
              {firstname} {lastname}
            </h1>
          </div>
          <img
            src={shareIcon}
            alt="Share"
            className="share-icon"
            onClick={handleShare}
          />
        </div>
      </div>

      <div className="widgets">{mainMetrics.map(renderMainWidget)}</div>

      <div className="category-title">Detailed Metrics</div>

      <div className="widgets">
        {detailedMetrics.map(renderDetailedWidget)}
        {demoMode && (
          <div className="widget precision-widget clickable">
            <span className="plus-icon">+</span>
            <span className="add-tests-text">
              Add metrics to improve accuracy
            </span>
          </div>
        )}
      </div>

      {demoMode && (
        <>
          <div className="category-title">Goals</div>
          <div className="empty-state clickable">
            <span className="plus-icon">+</span>
            <span className="empty-state-message">Set Your Next Goal</span>
          </div>
        </>
      )}

      <img src={stravaLogo} alt="Powered by Strava" className="stravaLogo" />
    </div>
  );
};

export default Profile;
