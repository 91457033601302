import React from "react";

const Support = () => (
  <div className="support">
    <h1>Support - More Life</h1>
    <p>
      Welcome to the support page for <strong>More Life</strong>, your go-to app
      for training with longevity in mind. We're here to ensure you get the most
      out of your journey with More Life, helping you seamlessly connect your
      Strava data and optimize your training for a longer, healthier life.
    </p>

    <h2>Contact Support</h2>
    <p>
      If you have any questions, issues, or feedback regarding the More Life
      app, we're just an email away. Whether you're facing technical
      difficulties or simply want to learn more about how to maximize your
      training for longevity, we're here to help.
    </p>
    <p>
      <strong>Email:</strong>{" "}
      <a href="mailto:support@getmorelife.io">support@getmorelife.io</a>
    </p>
    <p>
      We strive to respond to all inquiries within 24 hours. Please provide as
      much detail as possible in your email so we can assist you promptly and
      effectively.
    </p>

    <h2>Privacy Policy</h2>
    <p>
      At <strong>More Life</strong>, we prioritize your privacy. We handle your
      personal information and Strava data with the utmost care, ensuring it’s
      used solely to enhance your training experience. To learn more about how
      we protect your data, please review our{" "}
      <a href="/privacy-policy">Privacy Policy</a>.
    </p>
    {/* 
    <h2>Feedback</h2>
    <p>
      Your feedback is invaluable to us. If you have suggestions on how we can
      improve More Life or ideas for new features, please don't hesitate to
      share them with us.
    </p>
    <p>
      <a href="/feedback">Submit Feedback</a>
    </p> */}
  </div>
);

export default Support;
