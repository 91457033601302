// src/components/reusable/TextInput.js
import React, { useState } from "react";
import "./TextInput.scss";

const TextInput = ({ placeholder, value, onChange, validation, showError }) => {
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const newValue = e.target.value;
    onChange(e);
    if (validation) {
      const { validated, error_message } = validation(newValue);
      setError(validated ? "" : error_message);
    }
  };

  return (
    <div className="textInput">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        className={error && showError ? "error" : ""}
      />
      {error && showError && <span className="errorMessage">{error}</span>}
    </div>
  );
};

export default TextInput;
